.unauthorized {
    margin: auto;
    background-color: #F2F3F7;
    min-height: 80vh;
}

.unauthorized-img {
    height: 300px;
    width: 300px;
    margin-top: 5%;
}

.unauthorized-text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    margin-top: 16px;
    color: red;
}