.store-table {
    height: 55vh;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 16px 16px 48px;
    background-color: #ffffff;
    padding: 24px 40px 40px
}

.store-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.store-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 0px;
}

.store-table::-webkit-scrollbar-thumb {
    background: #006B8D;
    border-radius: 5px;
}

.store-table::-webkit-scrollbar-thumb:hover {
    background: #006b8dab;
    transform: scale(1.5);
}

.sp-mx-40 {
    margin: 0px 40px;
}