.cv {
    margin: 16px;
    padding: 24px;
}

.cv-header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: black;
    margin-bottom: 16px;
}

.cv-table {
    background-color: white;
    padding: 16px 16px 0px 16px;
    border-radius: 5px;
    min-height: 50vh;
}

tbody>tr:last-child {
    border-bottom-color: transparent;
}

.th-input {
    width: 10%;
}

.table-input {
    width: 50%;
}

.btn-download,
.btn-po,
.btn-upload,
.btn-save,
.btn-submit {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
}

.btn-download {
    background-color: #6558F5;
}

.btn-gdn {
    background-color: #5BA8DC !important;
    color: #ffffff !important;
}

.btn-grn {
    background-color: #00C4A6 !important;
    color: #ffffff !important;
}

.btn-po {
    background-color: #2C88D9;
}

.btn-upload {
    background-color: #730FC3;
}

.btn-save {
    background-color: #f14e23 !important;
}

/* .btn-submit {
    background-color: #E8833A;
} */

.grn-head {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: #000000 !important;
}

.grn-subhead {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000000;
}