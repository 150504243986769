.lpn-hm {
    background-color: #F2F3F7;
    min-height: 100vh;
    padding: 8px 24px;
}

.lpn-table {
    margin: 16px 16px 48px;
    background-color: #ffffff;
    padding: 40px;
    overflow-y: scroll;
    height: 60vh;
}

.lpn-head {
    padding: 24px 24px 0px;
}

.lpn-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.lpn-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 0px;
}

.lpn-table::-webkit-scrollbar-thumb {
    background: #006B8D;
    border-radius: 5px;
}

.lpn-table::-webkit-scrollbar-thumb:hover {
    background: #006b8dab;
    transform: scale(1.5);
}