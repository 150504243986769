.cv {
    margin: 16px;
    padding: 24px;
}

.cv-header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: black;
    margin-bottom: 16px;
}

.cv-table {
    background-color: white;
    padding: 16px 16px 0px 16px;
    border-radius: 5px;
    min-height: 50vh;
}

tbody>tr:last-child {
    border-bottom-color: transparent;
}

.th-input {
    width: 10%;
}

.table-input {
    width: 50%;
}

.btn-download,
.btn-po,
.btn-upload,
.btn-save,
.btn-submit,
.btn-danger,
.btn-warning {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
}

.btn-download {
    background-color: #6558F5 !important;
}

.btn-po {
    background-color: #2C88D9;
}

.btn-upload {
    background-color: #C3CFD9 !important;
    color: black !important;
}

.btn-save {
    background-color: #D3455B;
}

.btn-submit {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    color: #ffffff !important;
}