.submit-form {
    margin: 0px 16px;
    padding: 24px;
    border-radius: 5px;
    background-color: #ffffff;
}

.form {
    width: 50%;
}

.form-field {
    margin-bottom: 18px;
}

.form-control {
    border: 1px solid #D8E0E6;
}

.sf-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.amend-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: red;
}

.sf-sup {
    margin-right: 50%;
}

.btn-form {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    background-color: #E8833A;
    width: 250px;
    border-radius: 3px;
}

.sf-btn-group {
    margin: 24px 24px 16px 24px;
}

.sf-response-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.sf-response-data {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.response-view {
    margin-left: 10%;
}

.btn-prc-sub {
    background-color: #5BA8DC !important;
    color: white !important;
    /* font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px; */
}