.cv {
    margin: 16px;
    padding: 24px;
}

.cv-header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: black;
    margin-bottom: 16px;
}

.cv-table {
    background-color: white;
    padding: 16px 16px 0px 16px;
    border-radius: 5px;
    min-height: 50vh;
}

tbody>tr:last-child {
    border-bottom-color: transparent;
}

.th-input {
    width: 10%;
}

.table-input {
    width: 100%;
    height: 40px;
}

.table-drop {
    width: 100%;
    height: 40px;
}

.btn-download,
.btn-po,
.btn-upload,
.btn-save,
.btn-submit {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
}

.btn-download {
    background-color: #6558F5;
}

.btn-po {
    background-color: #2C88D9;
}

.btn-upload {
    background-color: #730FC3;
}

.btn-save {
    background-color: #808080 !important;
    color: #ffffff !important;
}

/* .btn-submit {
    background-color: #E8833A;
} */

.border-red {
    border: 2px solid red !important;
}

.delete-icon {
    height: 30px;
    width: 30px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.add-icon {
    height: 40px;
    width: 40px;
    cursor: pointer;
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 5px;
}

.ms-88 {
    margin-left: 88px;
}

.reason-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: black !important;
    margin-right: 14px;
    cursor: pointer;
}