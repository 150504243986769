.btn-amend {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #6558F5;
    color: #ffffff;
}

.btn-create-gdn {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #F7C325;
}

.btn-raise {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #C3CFD9 !important;
    border-color: #C3CFD9 !important;
}

.mismatch-header-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    white-space: nowrap;
    color: red;
}

.mismatch-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* white-space: nowrap; */
    color: black;
}

.submit-gdn-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
    color: #000000;
}

.error-icon {
    color: #D3455B;
}

.success-mail-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #24B1A3;
}

.content-table {
    overflow-y: scroll;
}

.content-table::-webkit-scrollbar {
    overflow-y: hidden;
    width: 0px;
}