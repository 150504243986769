.submit-form {
    margin: 0px 16px;
    padding: 24px;
    border-radius: 5px;
}

.form {
    width: 50%;
}

.form-field {
    margin-bottom: 18px;
}

.form-control {
    border: 1px solid #D8E0E6;
}

.gdn-sf-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
    color: black;
}

.sf-sup {
    margin-right: 35%;
}

.btn-form {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    background-color: #E8833A;
    width: 250px;
    border-radius: 3px;
}

.sf-btn-group {
    margin: 24px 24px 16px 24px;
}

.search-input {
    border-right: none;
}

.input-group-text {
    border-left: none;
    background-color: white;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}