.rt-table {
    margin: 16px 16px 0px;
    background-color: #ffffff;
    padding: 40px;
    overflow-y: scroll;
    /* height: 70vh; */
}

.rt-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.rt-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 0px;
}

.rt-table::-webkit-scrollbar-thumb {
    background: #006B8D;
    border-radius: 5px;
}

.rt-table::-webkit-scrollbar-thumb:hover {
    background: #006b8dab;
    transform: scale(1.5);
}

.rp-search {
    position: relative;
}

.rp-search-close {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}