.up-inv {
    background-color: #F2F3F7;
    min-height: 80vh;
}

.up-inv-form {
    background-color: #ffffff;
    margin: 0px 16px;
    padding: 24px;
    border-radius: 5px;
    height: 60vh;
}

.upload-invoice {
    width: 165px;
    position: absolute;
    border: 2px solid black;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.btn-upload-file:disabled {
    background-color: #c9c9c9 !important;
    border: none;
}

.btn-upload-file {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
    background-color: #1AAE9F !important;
    border-color: #1AAE9F !important;
}

.react-datepicker-wrapper {
    margin-left: auto;
    width: 50%;
}

.calendar-icon {
    height: 25px;
    width: 25px;
    position: absolute;
    right: 3px;
}

.btn-more {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
    background-color: #f61336 !important;
    border-color: #f61336 !important;
}

.btn-more:disabled {
    background-color: #c9c9c9 !important;
    border-color: #c9c9c9 !important;
}