.ls-grn {
    background-color: #F2F3F7;
    min-height: 79vh;
}

.grn-list {
    margin: 0px 16px 16px;
    background-color: white;
    padding: 24px;
    height: 70vh;
}


.list-items {
    overflow-x: scroll;
    height: 50vh;
}

.list-items::-webkit-scrollbar {
    overflow-x: hidden;
    width: 10px;
    height: 0px;
}

.list-items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 0px;
}

.list-items::-webkit-scrollbar-thumb {
    background: #006B8D;
    border-radius: 5px;
}

.list-items::-webkit-scrollbar-thumb:hover {
    background: #006b8dab;
    transform: scale(1.5);
}

.list-card:first-child {
    margin-top: 10px;
}

.list-card {
    background-color: #c9c9c933;
    border: none;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 19%);
}

.btn-list-proceed {
    position: absolute;
    bottom: 10px;
    z-index: 1;
}

.list-text-key {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black !important;
}

.list-text-value {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: black !important;
}