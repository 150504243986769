.navbar {
    background-color: #ffffff;
    padding: 16px 0px;
}

.nav-border {
    border-bottom: 1px solid #D8E0E6;
}

.nav-expand {
    width: 60%;
}

.nav-pad {
    margin-left: 40px;
}

.nav-link:hover {
    color: rgb(183, 179, 179) !important;
}

.nav-text,
.nav-link {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: black !important;
    margin-right: 14px;
    cursor: pointer;
}

.dropdown-toggle::after {
    opacity: 0;
}

.arrow {
    box-sizing: border-box;
    height: 6px;
    width: 6px;
    border-style: solid;
    border-color: #7E82A6;
    border-width: 0px 1px 1px 0px;
    transform: rotate(45deg);
    transition: border-width 150ms ease-in-out;
    margin: 0px 0px 3px 10px;
}

.sub-arrow {
    box-sizing: border-box;
    height: 6px;
    width: 6px;
    border-style: solid;
    border-color: #000000;
    border-width: 0px 1px 1px 0px;
    transform: rotate(-45deg);
    transition: border-width 150ms ease-in-out;
    margin: 0px 0px 3px 10px;
}

.dropdown:hover .dropdown-menu {
    /* display: block; */
    /* margin-top: 0; */
}


.dropdown-submenu {
    position: absolute !important;
    top: 0 !important;
    left: 202px !important;
}

.dropdown-menu {
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-width: 200px;
    padding: 0px;
}

.dropdown-menu>li:hover {
    background-color: #a9a9a978;
    color: white !important;
}

.dropdown-menu>li {
    padding: 8px;
    border-bottom: 1px solid #dee2e6;
}

.dropdown-item:hover {
    background-color: transparent;
    color: white !important;
}

.dropdown-menu>li:last-child {
    border-bottom: none;
}

.dropdown-item {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.nav-active,
.nav-active:hover {
    background-color: #006B8D;
    border-radius: 5px;
}

.nav-arrow {
    border-color: white !important;
}

.nav-active-color {
    color: white !important;
}

.nav-left-pad {
    padding-left: 40px !important;
}

.nav-disable {
    opacity: 0.5;
    cursor: no-drop !important;
}

.maintain-container {
    height: 25px;
    background-color: #F04E23;
    text-align: center;
    margin-top: 10px;
    width: 100%;
}

.maintain-container-sm {
    height: 50px;
    background-color: #F04E23;
    text-align: center;
    margin-top: 10px;
    width: 100%;
}

.maintain-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
}

.maintain-text.anime {
    animation-name: rightToLeft;
    animation-duration: 9s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes rightToLeft {
    0% {
        transform: translateX(1000px);
    }

    100% {
        transform: translateX(-1000px);
    }
}