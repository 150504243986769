.pnf-container {
    height: 100vh;
    background-color: #F2F3F7;
}

.not-found-img {
    height: 250px;
    width: 250px;
}

.arrow-icon {
    height: 20px;
    width: 20px;
}

.not-found-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    color: black !important;
}

.return-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white !important;
}