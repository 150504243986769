.au {
    background-color: #F2F3F7;
    min-height: 80vh;
    padding: 8px 24px;
}

.au-tabs {
    background-color: white;
    min-height: 70vh;
    width: 100%;
}

.au-nav {
    border-radius: 0px !important;
    border-width: 0px 0px 2px 0px !important;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
    padding: 10px 24px;
    margin-right: 3px;
}

.au-nav:hover {
    border-color: transparent !important;
    background-color: #D7E7F7 !important;
    border-bottom: 5px solid #006B8D !important;
}

.au-nav:disabled {
    opacity: 0.5;
}

.au-nav.active {
    border-bottom: 5px solid #006B8D !important;
    background-color: #D7E7F7 !important;
    font-weight: 700;
}

.au-content {
    padding: 24px;
}

.au-subtext {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: black;
}

.au-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
}

.au-modtext {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: black;
}