.add-hm {
    background-color: #F2F3F7;
    min-height: 100vh;
    padding: 8px 24px;
}

.add-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: black;
    width: 50%;
}

.add-btn {
    background-color: #1AAE9F;
    padding: 2px 8px;
    margin-left: 24px;
    color: #ffffff;
    cursor: pointer;
}

.sub-btn {
    background-color: #D3455B;
    border-radius: 50%;
    padding: 4px 14px;
    margin-left: 24px;
    color: #ffffff;
    cursor: pointer;
}

.btn-add-submit {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border-radius: 5px;
    background-color: #00C4A6;
}

.btn-cancel-add {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #6558F5;
    color: #ffffff;
}

.add-item-head {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: black;
}

.submit-add-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
}

.add-btn:disabled,
.sub-btn:disabled {
    opacity: 0.5
}

.add-sf-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
    color: black;
}

.item-list {
    margin-top: 5%;
    padding: 0px 16px;
}