.rg-hm {
    background-color: #F2F3F7;
    min-height: 100vh;
    padding: 8px 24px;
}

.rg-table {
    margin: 16px 16px 0px;
    background-color: #ffffff;
    padding: 40px;
    overflow-y: scroll;
    height: 75vh;
}

.rg-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.rg-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 0px;
}

.rg-table::-webkit-scrollbar-thumb {
    background: #006B8D;
    border-radius: 5px;
}

.rg-table::-webkit-scrollbar-thumb:hover {
    background: #006b8dab;
    transform: scale(1.5);
}

.rg-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}