.login {
    min-height: 100vh;
    background-color: #FFFFFF;

}

.login-content {
    height: 100vh;
}

.lg-logo {
    max-height: 75px !important;
}

.lg-head {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    margin-bottom: 16px;
}

.lg-subhead {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #B5B5C3;
    text-align: center;
}

.lg-form {
    margin-top: 24px;
    width: 100%;
}

.log-input {
    background-color: #F3F6F9 !important;
    border-color: #F3F6F9 !important;
    height: 50px;
}

.log-input::placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #C3B5C3 !important;
}

.form-bt-group {
    margin-bottom: 24px;
}

.form-tp-group {
    margin-top: 16px;
}

.bd-red {
    border: 2px solid red !important;
}

.lg-form-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    margin-right: 60px;
}

.lg-ps-label {
    margin-right: 63px;
}

.btn-signin {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
    border-radius: 5px;
    background-color: #006B8D !important;
    position: relative;
}

.btn-fp {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    background-color: transparent !important;
    color: #80808087 !important;
    border: none;
    margin-top: 8px;
}

.btn-fp:hover {
    transform: scale(1.1);
}

.lg-btn-grp {
    margin-top: 16px;
}

.error {
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-weight: 500;
    margin-left: 2px;
    margin-top: 2px;
    color: red;
}

.error-text {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
    margin-left: 2px;
    margin-top: 2px;
    color: red;
}

.btn-signin.loading {
    opacity: 0.5;
}

.btn-signin.loading:after {
    content: "";
    position: absolute;
    border-radius: 100%;
    right: 6px;
    top: 50%;
    width: 0px;
    height: 0px;
    margin-top: -2px;
    border: 3px solid rgba(255, 255, 255, 0.5);
    border-left-color: #7b7878;
    border-top-color: #7b78783d;
    animation: spin .6s infinite linear, grow .3s forwards ease-out;
    margin-right: 0px;
}

@keyframes spin {
    to {
        transform: rotate(359deg);
    }
}

@keyframes grow {
    to {
        width: 14px;
        height: 14px;
        margin-top: -8px;
        right: 13px;
    }
}

.hide-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
}