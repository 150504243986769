.tub {
    background-color: #F2F3F7;
    min-height: 80vh;
    padding: 8px 24px;
}

.sm-nav.active {
    background-color: #F04E23 !important;
    color: white !important;
}

.sm-nav:hover,
.sm-btn-drop:hover {
    transform: none !important;
}

.sm-icon {
    height: 25px;
    width: 25px;
    margin-left: 10px;
    color: white;
}