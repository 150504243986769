.ad-view {
    background-color: #F2F3F7;
    min-height: 80vh;
}

.ad-content-view {
    background-color: #ffffff;
    margin: 0px 16px;
    padding: 24px;
    border-radius: 5px;
    height: 65vh;
}

.ad-content-head {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}

.ad-process-name {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
}

.grn-list-items {
    height: 45vh;
    overflow-y: scroll;
}

.grn-list-items::-webkit-scrollbar {
    overflow-y: hidden;
}

.back-icon {
    height: 20px;
    width: 20px;
}