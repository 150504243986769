.page-no {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    border: 1px solid #00000040;
    padding: 2px 10px;
    border-radius: 5px;
    background-color: white;
    cursor: pointer;
}

.page-no.active {
    background-color: #098BAA;
    color: white;
    border: none;
    font-weight: 700;
}

.page-btn {
    border: 1px solid #00000040;
    padding: 1px 8px;
    border-radius: 5px;
    background-color: white;
}

.page-img {
    height: 15px;
    width: 15px;
    object-fit: contain;
}