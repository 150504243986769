.error-msg-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    text-align: center;
    line-break: anywhere;
}

.modal-close {
    cursor: pointer;
}