.homepage {
    min-height: 100vh;
    background-color: #F2F3F7;
}

.wms-header {
    margin: 32px 0px 24px;
}

.homepage-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 54px;
    color: black;
}

.wms-text {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 56px;
    color: #006B8D;
    font-style: italic;
}

.home-icon {
    height: 100%;
    margin-bottom: 32px;
}