.hd {
    padding: 12px 60px 12px 34px;
    border-bottom: 1px solid #D8E0E6;
    background-color: #ffffff;
}

.head-icon {
    height: 35px;
    width: 95px;
    cursor: pointer;
}

.user-icon {
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.hd-detail {
    margin-right: 12px;
}

.hd-user {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: black;
}

.hd-role {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #00000075;
}

.mr-sm {
    margin-right: 4px !important;
}

.btn-logout {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: #006B8D !important;
    color: #ffffff !important;
    border-radius: 5px;
    height: 40px;
    padding: 8px 24px;
}