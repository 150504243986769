.mp-screen {
    background-color: #F2F3F7;
    min-height: 80vh;
}

.mp-tabs {
    margin: 24px 16px;
    background-color: white;
    min-height: 30vh;
    width: 100%;
}

.mp-nav {
    border-radius: 0px !important;
    border-width: 0px 0px 2px 0px !important;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
    padding: 10px 24px;
    margin-right: 3px;
    transform: scale(1) !important;
}

.mp-nav:hover {
    border-color: transparent !important;
}

.mp-nav:disabled {
    opacity: 0.5;
}

.mp-nav.active {
    border-bottom: 3px solid #006B8D !important;
    background-color: #D7E7F7 !important;
    font-weight: 700;
}

.up-box {
    width: 450px;
    text-align: center;
}

.sp-head-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000 !important;
    margin-bottom: 20px
}