.p-multiselect {
    border: 1px;
}

.p-placeholder {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 12px !important;
    text-align: left;
}

.p-multiselect-token {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
}

.p-multiselect-chip {
    padding: 1px !important;
}

.upload-form {
    height: 45px;
}