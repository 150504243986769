.header {
    margin: 16px;
    border-radius: 5px;
    background-color: #ffffff;
}

.header-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: black;
}

.main-header-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: black;
}

.progress-sec {
    width: 200px;
}

.progress-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #778189;
}

#progress {
    height: 20px;
    background-color: #ddd;
}

#progress-bar {
    height: 20px;
    background-color: #04AA6D;
}