.btn-extract {
    background-color: #098BAA !important;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
    white-space: nowrap;
    height: 40px;
}

.upload-mkt {
    position: absolute;
    border: 2px solid black;
    top: 0;
    opacity: 0;
    cursor: pointer;
}