.profile {
  background-color: #f2f3f7;
  min-height: 100vh;
}

.profile-div {
  background-color: #ffffff;
  margin: 28px auto;
  height: 50vh;
  width: 40%;
  padding: 8px 24px;
}

.profile-div-sm {
  background-color: #ffffff;
  margin: 28px;
  height: 50vh;
  padding: 8px 24px;
}

.profile-head {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin-top: 16px;
}

.profile-option {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.role-select {
  width: 100%;
}