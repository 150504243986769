.mkt {
    background-color: #F2F3F7;
    min-height: 80vh;
    padding: 8px 24px;
}

thead>tr>th {
    text-transform: capitalize;
}

.mkt-table {
    margin: 16px 16px 48px;
    background-color: #ffffff;
    padding: 40px;
    overflow-y: scroll;
    height: 60vh;
}

.mkt-table::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.mkt-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #8080808f;
    border-radius: 0px;
}

.mkt-table::-webkit-scrollbar-thumb {
    background: #006B8D;
    border-radius: 5px;
}

.mkt-table::-webkit-scrollbar-thumb:hover {
    background: #006b8dab;
    transform: scale(1.5);
}

.btn-mkt {
    background-color: #098BAA !important;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff !important;
}

.mkt-middle {
    margin: 0px 125px;
}

.mkt-left {
    margin-left: 125px;
}

.up-confirm {
    min-height: 50vh;
    padding: 24px;
}

.up-failed {
    min-height: 50vh;
    padding: 0px 24px 24px;
}

.text-red {
    color: red !important;
}

.up-head-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    white-space: nowrap;
}

.up-error {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: red;
    white-space: nowrap;
}