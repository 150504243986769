.gdn-success-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: black;
}

.btn-submit:disabled {
    background-color: #DCDCDC !important;
    border: none !important;
}

.no-gdn-item-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: red;
}