.am-subtext {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.am-navigate,
.am-page {
    padding: 4px 10px;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    display: flex;
    align-items: center;
}

.am-navigate {
    color: #F8AE9A;
    background-color: #FCF5F3;
}

.am-page {
    color: #FFD2D8;
    background-color: #FF6c6c;
}

.btn-am-download {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #F04E23;
    background-color: #FCF5F3;
    border-radius: 5px;
    margin-right: 16px;
}

.btn-am-refresh {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FCF5F3;
    background-color: #F04E23;
    border-radius: 5px;
}